.file-list-item {
    background-color: #2f2f2f; /* dark gray background */
    border-radius: 10px; /* rounded corners */
    padding: 10px; /* padding around items */
  }
  
  .file-list-item:hover {
    background-color: #686060; /* light gray background on hover */
  }
  
  .file-list-item-name {
    color: #fff; /* white text for the file name */
    display: inline-block; /* display items inline */
    margin-right: 10px; /* space between file name and size */
  }
  
  .file-list-item-size {
    color: #ddd; /* light gray text for the file size */
    display: inline-block; /* display items inline */
  }
  
  .file-list-container {
    width: 80%;
  }