.App-body {
    background-color: #000000;
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-body-player {
    background-color: #000000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-listbox {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: 15vh; /* Add a margin-top of 15% */
    height: calc(100vh - 15vh); /* Adjust the height to fill the remaining space */
  }
